import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "./terms.scss"

const TermsPage = () => (
  <Layout>
    <SEO title="Terms of use" />
    <section class="section textpage terms">
      <div className="section__inner">
        <h1>Terms of Service</h1>
        <h2>Using SizePipe.com means accepting these terms</h2>

        <p>
          By accessing or using SizePipe.com in any way, whether you have
          created your own account or been invited by your organization, you
          agree to and are bound by the terms and conditions written in this
          document.
        </p>

        <p>
          If you do not agree to all of the terms and conditions contained in
          this document, do not access SizePipe.com.
        </p>

        <h2>This is a living document</h2>

        <p>
          If you read something that rubs you the wrong way, or if you think of
          something that should be added, please get in touch! We're all ears!
          Email info@sizepipe.com and we'll chat.
        </p>

        <p>We will likely improve this document over time.</p>

        <p>
          Your access and use of SizePipe.com is always subject to the most
          current version of this document.
        </p>

        <h2>System requirements</h2>

        <p>
          A modern web browser is required. Internet Explorer is NOT supported.
        </p>

        <h2>Accuracy of calculations</h2>

        <h2>
          Can you guarantee the calculations I make on SizePipe.com is 100%
          accurate and never give an inaquarete result?
        </h2>

        <p>
          The algorithm to calculate pipe data have been extensively tested,
          both with so called unit tests, and also manual tests. However, we are
          all humans and we can never guarantee errors may never occur.{" "}
          <b>
            SizePipe.com do not take any responsibility for any possible errors.
          </b>{" "}
          If you find any errors we urge you to immediately take contact with us
          on info@sizepipe.com.
        </p>

        <h2>Support</h2>

        <p>
          Support for SizePipe.com is offered via email, info@sizepipe.com.
          Premium users are always prioritized but we strive to answer all
          emails.
        </p>

        <h2>Access to your data</h2>

        <h3>What personal data do you collect?</h3>

        <p>We collect the following personal data:</p>

        <ul>
          <li>Name</li>
          <li>E-mail address</li>
        </ul>

        <p>
          We also collect Billing Address and company information for Enterprise
          subscriptions
        </p>

        <p>
          We never use or share the personally identifiable information provided
          to us online in ways unrelated to the ones described above.
        </p>

        <h3>Who can see my password?</h3>

        <p>
          No-one. We store your password encrypted so no-one can read it. For
          encrypting the passwords, we use bcrypt hashing and a unique random
          salt for each user.
        </p>

        <h3>How is my data protected from another customer's data?</h3>

        <p>
          All of our customer's data resides in the same database. We use best
          practices for protecting your data. We do our best and are very
          confident we're doing a good job at it, but, like every other web app
          that hosts their customers data on the same database, cannot guarantee
          that a sophisticated hacker cannot access other people's or
          organizations data.
        </p>

        <h2>Reliability</h2>

        <h3>
          Do you guarantee that SizePipe.com will be accessible at all times?
        </h3>

        <p>
          In short, we do not. Like all other cloud-based applications, we are
          vulnerable to the inherent unreliability of the Internet. For the past
          two years, we have more than 99.99% uptime.
        </p>

        <Link to="/">Go back to the homepage</Link>
      </div>
    </section>
  </Layout>
)

export default TermsPage
